import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { login } from '../../handlers/users'

import './Login.css'

export default function Login(){
    const navigate = useNavigate()
    const [isLoad, setIsLoad] = useState(false)
    const [isPassword, setIsPassword] = useState(true)
    const [credentials, setCredentials] = useState({ email: '', password: '' })

    const handleLogin = async() => {
        setIsLoad(true)

        const response = await login(credentials)

        if(response.message){
            toast.error(response.message)
        } else {
            toast.success('Successfully Logged-in!')
            localStorage.setItem('jwt', response.token)
            localStorage.setItem('id', response.id)
            localStorage.setItem('email', response.email)
            navigate('/main-page')
        }
        setIsLoad(false)
    } 

    const handleSignIn = () => {
        toast.error('Sign-up Disabled')
    }

    return (
        <div className='body-container'>
            <div className='main-container'>
                <div className='form-container'>
                    <div className='form'>
                        <span className='heading lilita-one-regular'>DELVE</span>
                        <input placeholder='Email' type='email' className='input'
                            value={credentials.email}
                            onChange={e => setCredentials({ ...credentials, email: e.target.value })}
                            onKeyPress={e => e.key === 'Enter' ? handleLogin() : '' }
                        />
                        <input placeholder='Password' type={isPassword ? 'password' : 'text'} className='input'
                            value={credentials.password}
                            onChange={e => setCredentials({ ...credentials, password: e.target.value })}
                            onKeyPress={e => e.key === 'Enter' ? handleLogin() : '' }
                        />
                        <span className='orange' style={{float: 'right', cursor: 'pointer'}}
                            onClick={()=>setIsPassword(!isPassword)}
                        >
                            {isPassword ? 'Show Password' : 'Hide Password'}
                        </span>
                        <textarea className="textarea"></textarea>
                        <div className='button-container'>
                            <div className='send-button lilita-one-regular' onClick={handleLogin}>
                                {isLoad ? <i className="fa-solid fa-spinner fa-spin-pulse"></i> : 'Login'}
                            </div>
                            <div className='reset-button-container lilita-one-regular' onClick={handleSignIn}>
                                <div className='reset-button'>Sign Up</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}