import React, { useState } from 'react'
import Modal from 'react-modal'

import './Modals.css'

export default function ActivityLogs({ logs }) {
    const [showLogs, setShowLogs] = useState(false)

    const handleShowLogs = () => {
        setShowLogs(true)
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString)
        date.setUTCHours(date.getUTCHours())

        const options = {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: 'Asia/Manila'
        }

        return new Intl.DateTimeFormat('en-US', options).format(date)
    }

    return (
        <React.Fragment>
            <button className='btn btn-warning' onClick={handleShowLogs}>
                <i className='fa-solid fa-square-pen' /> Activity Logs
            </button>
            <Modal isOpen={showLogs} ariaHideApp={false}
                className='x_saveModal x_ddParserModal x_logsModal'
                onRequestClose={() => setShowLogs(false)}
            >
                <h4>Activity Logs:</h4>
                {logs.slice().reverse().map((logItem, index) => (
                    <div key={index} className='x_logItemContainer'>
                        <div><strong>Updated by: </strong>{logItem.updatedBy}</div>
                        <div><strong>Update Notes: </strong>{logItem.updateNotes}</div>
                        <div><strong>Date Updated: </strong>{formatDate(logItem.dateUpdated)}</div>
                    </div>
                ))}
            </Modal>
        </React.Fragment>
    )
}