import React from 'react'
import './TableLoader.css'

export default function TableLoader(){
    return (
        <div>
            <div className='spinner'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div className='label'>Loading....</div>
        </div>
    )
}