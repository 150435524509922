import { makeApiRequest, proxyUrl } from './_index'

export async function initializeWorker(body) {
    const url = `${proxyUrl}/api/worker/initializeWorker`
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    }

    return makeApiRequest(url, options)
}

export async function terminateWorker(body) {
    const url = `${proxyUrl}/api/worker/terminateWorker`
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    }

    return makeApiRequest(url, options)
}