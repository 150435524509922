import AWS from 'aws-sdk'

export function uploadFileToS3(s3Folder, file, fileName) {
    AWS.config.update({
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
        region: process.env.REACT_APP_S3_REGION
    })

    const s3 = new AWS.S3()
    const fileReader = new FileReader()
    fileReader.readAsArrayBuffer(file)

    return new Promise((resolve, reject) => {
        fileReader.onload = () => {
            const params = {
                Bucket: process.env.REACT_APP_S3_BUCKET,
                Key: `${s3Folder}/${fileName}`,
                Body: fileReader.result,
                ACL: process.env.REACT_APP_S3_ACL
            }

            s3.upload(params, function(err, data) {
                if (err) {
                    reject(err)
                } else {
                    resolve(data.Key)
                }
            })
        }

        fileReader.onerror = (error) => {
            reject(error)
        }
    })
}

export function createFolderInS3(path, folderName) {
    AWS.config.update({
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
        region: process.env.REACT_APP_S3_REGION
    })

    const s3 = new AWS.S3()

    return new Promise((resolve, reject) => {
        const params = {
            Bucket: process.env.REACT_APP_S3_BUCKET,
            Key: `${path}/${folderName}/`,
            ACL: process.env.REACT_APP_S3_ACL,
            Body: ''
        }

        s3.upload(params, function(err, data) {
            if (err) {
                reject(err)
            } else {
                resolve(data.Key)
            }
        })
    })
}