import { makeApiRequest, proxyUrl } from './_index'

export async function login(body) {
    const url = `${proxyUrl}/api/users/loginUser`
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    }

    return makeApiRequest(url, options)
}

export async function getUser(_id) {
    const url = `${proxyUrl}/api/users/getUser/${_id}`
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    }

    return makeApiRequest(url, options)
}

export async function getUsers(role, isActive) {
    const baseUrl = `${proxyUrl}/api/users/getUsers`
    const queryString = [
        `role=${role}`,
        `isActive=${isActive}`,
    ]
    const url = `${baseUrl}?${queryString.join('&')}`
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    }

    return makeApiRequest(url, options)
}

export async function changePassword(body) {
    const url = `${proxyUrl}/api/users/changePassword`
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    }

    return makeApiRequest(url, options)
}

export async function updateUser(_id, body) {
    const url = `${proxyUrl}/api/users/updateUser/${_id}`
    const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    }

    return makeApiRequest(url, options)
}