import React from 'react'
import { useNavigate } from 'react-router-dom'

import './Containers.css'

export default function ToolContainer(props) {
    const navigate = useNavigate()

    const configHandler = () => {
        navigate(`/configurations/${props.toolName}`)
    }

    return (
        <div className='x_card-container'>
            <div className='x_card card border-primary'>
                <div className='x_card-header card-header'>
                    {props.title || 'Empty'}
                    {!props.noConfig && (
                        <i onClick={configHandler} className='fa-solid fa-gear x_config-button' />
                    )}
                </div>
                {props.children}
            </div>
        </div>
    )
}