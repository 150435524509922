import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { changePassword, getUser, updateUser } from '../../handlers/users'
import { toast } from 'react-toastify'

import './MyProfile.css'

export default function MyProfile() {
    const navigate = useNavigate()
    const [originalPassword, setOriginalPassword] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [userData, setUserData] = useState({})
    const [toggleUpdate, setToggleUpdate] = useState(false)
    const [showOriginalPassword, setShowOriginalPassword] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [editedUserData, setEditedUserData] = useState({})

    const formatDate = (dateString) => {
        const date = new Date(dateString)
        return date.toLocaleDateString('en-US')
    }

    const handlePasswordChange = async (e) => {
        e.preventDefault()
        if ((password === confirmPassword) && originalPassword !== '') {
            const token = localStorage.getItem('jwt')
            const body = {
                _id: userData._id,
                token,
                oldPassword: originalPassword,
                newPassword: password,
                confirmNewPassword: confirmPassword
            }

            const response = await changePassword(body)
            if (response.message !== 'Password changed successfully') {
                toast.error('Incorrect credentials')
            } else {
                toast.success('Password changed successfully')
            }

            setToggleUpdate(!toggleUpdate)
        } else {
            toast.error('Passwords error')
        }
    }

    const handleGetUser = async () => {
        const userId = localStorage.getItem('id')
        const response = await getUser(userId)
        setUserData(response)
        setEditedUserData(response)
    }

    const handleEditUser = async () => {
        const response = await updateUser(userData._id, editedUserData)
        if (response.message === 'Successfully updated!') {
            toast.success('Profile updated successfully')
            setIsEditing(false)
            setToggleUpdate(!toggleUpdate)
        } else {
            toast.error('Error updating profile')
        }
    }

    const handleLogout = () => {
        localStorage.removeItem('jwt')
        localStorage.removeItem('id')
        navigate('/login')
    }

    useEffect(() => {
        handleGetUser()
        setOriginalPassword('')
        setPassword('')
        setConfirmPassword('')
    }, [toggleUpdate])

    return (
        <div className='y_profile-container'>
            <div className='y_card'>
                <h2 className='y_card-header'>
                    My Profile
                    <i className='x_logout fa-solid fa-right-from-bracket y_logout' onClick={handleLogout}></i>
                </h2>
                <div className='y_profile-info'>
                <div className='y_profile-details'>
                        {isEditing ? (
                            <>
                                <label>Email:</label>
                                <input className='y_input y_input3' type='text'
                                    value={editedUserData.email}
                                    onChange={(e) => setEditedUserData({ ...editedUserData, email: e.target.value })}
                                />
                                <label>First Name:</label>
                                <input className='y_input y_input3' type='text'
                                    value={editedUserData.firstName}
                                    onChange={(e) => setEditedUserData({ ...editedUserData, firstName: e.target.value })}
                                />
                                <label>Middle Name:</label>
                                <input className='y_input y_input3' type='text'
                                    value={editedUserData.middleName}
                                    onChange={(e) => setEditedUserData({ ...editedUserData, middleName: e.target.value })}
                                />
                                <label>Last Name:</label>
                                <input className='y_input y_input3' type='text'
                                    value={editedUserData.lastName}
                                    onChange={(e) => setEditedUserData({ ...editedUserData, lastName: e.target.value })}
                                />
                            </>
                        ) : (
                            <div className='y_profile-details2'>
                                <p><strong>Email:</strong> {userData.email}</p>
                                <p><strong>First Name:</strong> {userData.firstName}</p>
                                <p><strong>Middle Name:</strong> {userData.middleName || 'n/a'}</p>
                                <p><strong>Last Name:</strong> {userData.lastName}</p>
                                <p><strong>Role:</strong> {userData.role}</p>
                                <p><strong>Account Status:</strong> {userData.isActive ? 'Active' : 'Inactive'}</p>
                                <p><strong>Member Since:</strong> {formatDate(userData.createdAt)}</p>
                            </div>
                        )}
                    </div>
                </div>
                <div className='y_button-container'>
                    {isEditing ? (
                        <button className='y_edit-button' onClick={handleEditUser}>Save</button>
                    ) : (
                        <button className='y_edit-button' onClick={() => setIsEditing(true)}>Edit</button>
                    )}
                </div>
            </div>
            <div className='y_card y_card2'>
                <h2 className='y_h2'>Change Password</h2>
                <form className='y_password-change-form' onSubmit={handlePasswordChange}>
                    <div className='y_form-group'>
                        <label htmlFor='originalPassword'>Original Password:</label>
                        <div className='y_input-container'>
                            <input id='originalPassword' className='y_input'
                                type={showOriginalPassword ? 'text' : 'password'}
                                value={originalPassword}
                                onChange={(e) => setOriginalPassword(e.target.value)}
                            />
                            <button type='button' className='y_toggle-visibility'
                                onClick={() => setShowOriginalPassword(!showOriginalPassword)}
                            >
                                {showOriginalPassword ? 'Hide' : 'Show'}
                            </button>
                        </div>
                    </div>
                    <div className='y_form-group'>
                        <label htmlFor='newPassword'>New Password:</label>
                        <div className='y_input-container'>
                            <input id='newPassword' className='y_input'
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <button type='button' className='y_toggle-visibility'
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? 'Hide' : 'Show'}
                            </button>
                        </div>
                    </div>
                    <div className='y_form-group'>
                        <label htmlFor='confirmPassword'>Confirm Password:</label>
                        <div className='y_input-container'>
                            <input id='confirmPassword' className='y_input'
                                type={showConfirmPassword ? 'text' : 'password'}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            <button type='button' className='y_toggle-visibility'
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            >
                                {showConfirmPassword ? 'Hide' : 'Show'}
                            </button>
                        </div>
                    </div>
                    <button type='submit' className='y_change-pass-button'>Change Password</button>
                </form>
            </div>
        </div>
    )
}