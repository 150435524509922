import React from 'react'
import { jwtDecode  } from 'jwt-decode'
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom'

import Layout from './layout/Layout'
import PageNotFound from './views/PageNotFound/PageNotFound'
import Login from './views/LoginPage/Login'
import Downloads from './views/DownloadsPage/Downloads'
import MainPage from './views/MainPage/MainPage'
import TemplateParser_Config from './views/MainPage/Configurations/TemplateParser'
import DdParser_Config from './views/MainPage/Configurations/DdParser'
import LegacyV2_Config from './views/MainPage/Configurations/LegacyV2'
import MyProfile from './views/MyProfile/MyProfile'

import "@fontsource/lilita-one"
// import "bootswatch/dist/superhero/bootstrap.min.css"

export default function App() {
  return (
    <Router>
      <Routes>
        <Route exact path='/' element={ <Login /> } />
        <Route exact path='/login' element={<Login />} />
        <Route exact path='/' element={ <PrivateRoute /> }>
          <Route exact path='/main-page' element={<Layout content={MainPage} />} />
          <Route exact path='/configurations/template-parser' element={<Layout content={TemplateParser_Config} />} />
          <Route exact path='/configurations/dd-parser' element={<Layout content={DdParser_Config} />} />
          <Route exact path='/configurations/legacy-v2' element={<Layout content={LegacyV2_Config} />} />
          <Route exact path='/downloads' element={<Layout content={Downloads} />} />
          <Route exact path='/my-profile' element={<Layout content={MyProfile} />} />
          <Route exact path='/page-not-found' element={<PageNotFound />} />
          <Route exact path='*' element={<Navigate to='/page-not-found' />}/>
        </Route>
      </Routes>
    </Router>
  )
}

const isAuthenticated = () => {
	const token = localStorage.getItem('jwt')
	try {
		jwtDecode(token)
		return true
	} catch (error) {
    return false
	}
}

const PrivateRoute = () => {
	return isAuthenticated() ? <Outlet /> : <Navigate to='/login' />
}