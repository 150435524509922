import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getS3FolderContents } from '../../../handlers/s3'
import DdParserSetup from '../../../components/Modals/DdParser'

import './Tools.css'
import { uploadFileToS3 } from '../../../middlewares/AWS'
import { addDownload } from '../../../handlers/downloads'
import { initializeWorker } from '../../../handlers/workers'
import { useNavigate } from 'react-router-dom'
import { getToolConfig } from '../../../handlers/configs'
import { getUser } from '../../../handlers/users'

export default function DdParser() {
    const isQA = process.env.REACT_APP_NODE_ENV === 'development'
    const navigate = useNavigate()

    const [inputs, setInputs] = useState({
        sourceType: 'Data Central',
        ddType: 'Regular DD',
        csddFile: 'Loading...',
        ddFile: null,
    })

    const [csddFiles, setCsddFiles] = useState([])
    const [isLoadingCsdd, setIsLoadingCsdd] = useState(false)
    const [isLoading, setIsloading] = useState(false)
    const [sheetsConfigs, setSheetsConfigs] = useState([])

    const handleSelectChange = (event) => {
        const { id, value } = event.target
        setInputs(prevState => ({ ...prevState, [id]: value }))

        if(id === 'ddType') getCsddList(value)
    }

    const handleCsddFileInputChange = (event) => {
        const fileName = event.target.value
        console.log('hey: ',event.target.textContent)
        setInputs(prevState => ({ ...prevState, csddFile: fileName }))
    }

    const handleDdFileInputChange = (event) => {
        const file = event.target.files[0]
        setInputs(prevState => ({ ...prevState, ddFile: file }))
    }

    const handleSubmit = async (selectedHeaders) => {
        console.log('Form submitted with inputs:', inputs, selectedHeaders)
        setIsloading(true)

        const userData = await getUser(localStorage.getItem('id'))
        if(userData.email !== localStorage.getItem('email')){
            toast.error('Hacker alert!')
            setIsloading(false)
            return
        }

        const batchId = `DDT-${Date.now()}${isQA ? ' (QA)' : ''}`
        const keyName = await uploadFileToS3('imports', inputs.ddFile, `(${batchId})${inputs.ddFile.name}`)
        const requestBody = {
            batchId,
            category: 'dd-parser',
            importName: inputs.ddFile.name.replace('.xlsx', ''),
            user: userData.email,
            originalFile: keyName,
            options: {...inputs, includedHeaders: selectedHeaders},
        }
        const response = await addDownload(requestBody)
        await initializeWorker(response.download)
        navigate('/downloads')

        toast.success('Submitted!')
        setIsloading(false)
    }

    const getCsddList = async(ddType) => {
        let path = ''

        switch(ddType){
            case 'Regular DD':
                path = 'cs-dd/regular/'
                break
            case 'Canada DD':
                path = 'cs-dd/canada/'
                break
            case '3PL DD':
                path = 'cs-dd/3pl/'
                break
            case '2P DD':
                path = 'cs-dd/2p/'
                break
            default: 
                path = 'cs-dd'
                console.log('ddType Error')
        }

        setIsLoadingCsdd(true)
        const response = await getS3FolderContents(path)
        setIsLoadingCsdd(false)

        if (response && response.files) {
            setCsddFiles(response.files)
            setInputs(prevState => ({
                ...prevState,
                csddFile: response.files.length > 0 ? response.files[0].Key : null
            }))
        }
    }

    const getSheetsConfigs = async() => {
        const response = await getToolConfig('dd-parser')
        setSheetsConfigs(response.configs)
    }

    const handleDownloadCsdd = (csddFile) => {
        const downloadUrl = `https://demand-data-tools.s3.amazonaws.com/${csddFile}`
        const anchor = document.createElement('a')
        anchor.href = downloadUrl
        anchor.setAttribute('download', '')
        anchor.click()
    }

    useEffect(()=>{
        getCsddList(inputs.ddType)
        getSheetsConfigs()
    },[])

    return (
        <React.Fragment>
            <div className='x_card-body x_card-body2 card-body'>
                <div className='x_select-mainContainer'>
                    <div className='x_select-container'>
                        <label htmlFor='sourceType' className='x_label form-label'>Source Type:</label>
                        <select className='x_form-select form-select' id='sourceType' onChange={handleSelectChange} value={inputs.sourceType}>
                            <option value='Data Central'>Data Central</option>
                            <option value='Legacy' disabled>Legacy</option>
                        </select>
                    </div>
                    <div className='x_select-container'>
                        <label htmlFor='ddType' className='x_label form-label'>DD Type:</label>
                        <select className='x_form-select form-select' id='ddType' onChange={handleSelectChange} value={inputs.ddType}>
                            <option value='Regular DD'>Regular DD</option>
                            <option value='Canada DD'>Canada DD</option>
                            <option value='3PL DD'>3PL DD</option>
                            <option value='2P DD'>2P DD</option>
                        </select>
                    </div>
                </div>
                <div className='x_fileInput-container'>
                    <label className='x_label form-label' style={{cursor:'pointer'}} onClick={()=>handleDownloadCsdd(inputs.csddFile)}>CS DD File:</label>
                    <select className='x_form-select form-select' id='csddSource' onChange={handleCsddFileInputChange} value={inputs.csddFile} disabled={isLoadingCsdd}>
                        {isLoadingCsdd ? <option value='loading..'>Loading...</option> : 
                            csddFiles.map((file, index) => (
                            <option key={index} value={file.Key}>{file.FileName}</option>
                        ))}
                    </select>
                </div>
                <div className='x_fileInput-container'>
                    <label htmlFor='fileInput2' className='x_label form-label'>DD File:</label>
                    <input className='x_fileInput form-control' type='file' id='fileInput2' accept='.xlsb, .xlsx' onChange={handleDdFileInputChange} />
                </div>
            </div>
            <div className='x_card-footer card-footer'>
                <DdParserSetup 
                    inputs={inputs} 
                    handleParse={handleSubmit}
                    isLoadingCsdd={isLoadingCsdd}
                    loading={{isLoading, setIsloading}}
                    sheetsConfigs={sheetsConfigs}
                />
            </div>
        </React.Fragment>    
    )
}