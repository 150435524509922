import React from 'react'
import ToolContainer from '../../components/Containers/ToolContainer'
import TemplateParser from './Tools/TemplateParser'
import DdParser from './Tools/DdParser'

import './MainPage.css'
import LegacyV2 from './Tools/LegacyV2'

export default function MainPage(){
    return (
        <div className='x_body-container'>
            <ToolContainer title={'DD Report to DD Template'} toolName={'template-parser'}>
                <TemplateParser />
            </ToolContainer>
            <ToolContainer title={'CS DD to DD Report'} toolName={'dd-parser'}>
                <DdParser />
            </ToolContainer>
            <ToolContainer title={'Data Central Mini'} toolName={'legacy-v2'}>
                <LegacyV2 />
            </ToolContainer>
        </div>
    )
}