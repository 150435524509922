// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.x_card-container {
    width: 25%;
    padding: 1rem;
    height: 42vh;
}

.x_card {
    height: 100%;
    border: 5px solid #78899adc !important;
    border-radius: 5px;
    box-shadow: 5px 5px 2px #7DCAC5;
    background-color: rgba(255, 255, 255, 0);
}

.x_card:hover {
    border: 5px solid #053f5caf !important;
}

.x_card-header {
    font-weight: bold;
    box-shadow: 0 2px 2px darkslategray;
    background-color: #B8CCD9;
    color: #053F5C;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.x_config-button {
    cursor: pointer;
    font-size: large;
}

.x_config-button:hover {
    color: black;
}`, "",{"version":3,"sources":["webpack://./src/components/Containers/Containers.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,sCAAsC;IACtC,kBAAkB;IAClB,+BAA+B;IAC/B,wCAAwC;AAC5C;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,iBAAiB;IACjB,mCAAmC;IACnC,yBAAyB;IACzB,cAAc;IACd,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".x_card-container {\n    width: 25%;\n    padding: 1rem;\n    height: 42vh;\n}\n\n.x_card {\n    height: 100%;\n    border: 5px solid #78899adc !important;\n    border-radius: 5px;\n    box-shadow: 5px 5px 2px #7DCAC5;\n    background-color: rgba(255, 255, 255, 0);\n}\n\n.x_card:hover {\n    border: 5px solid #053f5caf !important;\n}\n\n.x_card-header {\n    font-weight: bold;\n    box-shadow: 0 2px 2px darkslategray;\n    background-color: #B8CCD9;\n    color: #053F5C;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.x_config-button {\n    cursor: pointer;\n    font-size: large;\n}\n\n.x_config-button:hover {\n    color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
