import { makeApiRequest, proxyUrl } from './_index'

export async function getFunctions() {
    const url = `${proxyUrl}/api/functions/getFunctions`
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    }

    return makeApiRequest(url, options)
}

export async function addFunction(body) {
    const url = `${proxyUrl}/api/functions/addFunction`
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    }

    return makeApiRequest(url, options)
}

export async function modifyFunction(id, body) {
    const url = `${proxyUrl}/api/functions/modifyFunction/${id}`
    const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    }

    return makeApiRequest(url, options)
}

export async function deleteFunction(id) {
    const url = `${proxyUrl}/api/functions/deleteFunction/${id}`
    const options = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' }
    }

    return makeApiRequest(url, options)
}