import { makeApiRequest, proxyUrl } from './_index'

export async function getDownloads(limit, page, importName, status, batchId, category, user) {
    const baseUrl = `${proxyUrl}/api/downloads/getDownloads`
    const queryString = [
        `limit=${limit}`,
        `page=${page}`,
        `importName=${importName}`,
        `status=${status}`,
        `batchId=${batchId}`,
        `category=${category}`,
        `user=${user}`,
    ]
    const url = `${baseUrl}?${queryString.join('&')}`
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    }

    return makeApiRequest(url, options)
}

export async function getUniqueFieldValues(field) {
    const url = `${proxyUrl}/api/downloads/getUniqueFieldValues?field=${field}`
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    }

    return makeApiRequest(url, options)
}

export async function addDownload(body) {
    const url = `${proxyUrl}/api/downloads/addDownload`
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    }

    return makeApiRequest(url, options)
}

export async function getDownload(batchId) {
    const url = `${proxyUrl}/api/downloads/getDownload/${batchId}`
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    }

    return makeApiRequest(url, options)
}

export async function editDownload(batchId, body) {
    const url = `${proxyUrl}/api/downloads/editDownload/${batchId}`
    const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    }

    return makeApiRequest(url, options)
}

export async function deleteDownload(batchId) {
    const url = `${proxyUrl}/api/downloads/deleteDownload/${batchId}`
    const options = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' }
    }

    return makeApiRequest(url, options)
}