import { makeApiRequest, proxyUrl } from './_index'

export async function getS3FolderContents(folderName) {
    const encodedFolderName = encodeURIComponent(folderName)
    const url = `${proxyUrl}/api/s3/folders/get-list/${encodedFolderName}`
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    }

    return makeApiRequest(url, options)
}