import { makeApiRequest, proxyUrl } from './_index'

export async function getToolConfig(toolName) {
    const url = `${proxyUrl}/api/config/getToolConfig/${toolName}`
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    }

    return makeApiRequest(url, options)
}

export async function replaceConfig(toolName, name, newConfig) {
    const url = `${proxyUrl}/api/config/replaceConfig/${toolName}/${name}`
    const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newConfig)
    }

    return makeApiRequest(url, options)
}

export async function replaceAllConfigs(toolName, configArray) {
    const url = `${proxyUrl}/api/config/replaceAllConfigs/${toolName}`
    const options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(configArray)
    }

    return makeApiRequest(url, options)
}

export async function addUpdateMade(toolName, newUpdate) {
    const url = `${proxyUrl}/api/config/addUpdateMade/${toolName}`
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newUpdate)
    }

    return makeApiRequest(url, options)
}