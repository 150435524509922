// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    position: relative;
    cursor: pointer;
}

.content {
    position: relative;
}

.tooltip {
    content: "";
    position: absolute;
    top: -30%;
    right: -243%;
    background-color: #C0D7DF;
    color: black;
    border-radius: 4px;
    padding: .5rem;
    width: 63vw;
    z-index: -1;
}

.show {
    opacity: 1;
    z-index: 1;
}

.tooltip-hidden {
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/views/MainPage/Configurations/Utils/Utils.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,cAAc;IACd,WAAW;IACX,WAAW;AACf;;AAEA;IACI,UAAU;IACV,UAAU;AACd;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".container {\n    position: relative;\n    cursor: pointer;\n}\n\n.content {\n    position: relative;\n}\n\n.tooltip {\n    content: \"\";\n    position: absolute;\n    top: -30%;\n    right: -243%;\n    background-color: #C0D7DF;\n    color: black;\n    border-radius: 4px;\n    padding: .5rem;\n    width: 63vw;\n    z-index: -1;\n}\n\n.show {\n    opacity: 1;\n    z-index: 1;\n}\n\n.tooltip-hidden {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
