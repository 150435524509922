import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Logo from './Logo.png'
import packageJson from '../../package.json'

import './Layout.css'

export default function Header() {
    const initialParams = '/downloads?limit=15&page=1&importName=&status=&batchId=&category=&user='
    const navigate = useNavigate()
    const location = useLocation()
    const [activeTab, setActiveTab] = useState('')

    useEffect(() => {
        const currentPath = location.pathname.split('?')[0]
        const initialPath = initialParams.split('?')[0]
        if (currentPath === '/main-page') {
            setActiveTab('main-page')
        } else if (currentPath === initialPath) {
            setActiveTab('downloads')
        } else if (currentPath === '/my-profile') {
            setActiveTab('my-profile')
        }
    }, [location.pathname])

    return (
        <nav className='x_navbar navbar navbar-expand-lg bg-primary lilita-one-regular' data-bs-theme='dark'>
            <div className='container-fluid'>
                <div className='x_navBar_label' onClick={() => navigate('/main-page')}>
                    <span className='image-container'>
                        <img src={Logo} className='image' alt='Delve Logo'/>
                    </span>
                    <span>DELVE</span>
                    <span className='x_version-container'>v{packageJson.version}</span>
                </div>
                <div className='collapse navbar-collapse' id='navbarColor01'>
                    <ul className='navbar-nav me-auto x_navOptions'>
                        <li className='nav-item'>
                            <a className={`x_nav-link nav-link ${activeTab === 'main-page' ? 'x_nav-link-active' : ''}`} href='/main-page'>Tools</a>
                        </li>
                        <li className='nav-item'>
                            <a className={`x_nav-link nav-link ${activeTab === 'downloads' ? 'x_nav-link-active' : ''}`} href={initialParams}>Downloads</a>
                        </li>
                        <li className='nav-item'>
                            <a className={`x_nav-link nav-link ${activeTab === 'my-profile' ? 'x_nav-link-active' : ''}`} href='/my-profile'>
                                <i className='fa-solid fa-user'></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}