import React from 'react'
import Header from './Header'
import Footer from './Footer'

export default function Layout({ content: Content }){
    return (
        <React.Fragment>
            <Header />
            <Content />
            <Footer />
        </React.Fragment>
    )
}