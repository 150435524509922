// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/FINAL.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.x_body-container {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
    height: auto;
    max-height: 91vh;
    overflow-y: scroll;
}

.x_body-container::before {
    content: '';
    position: absolute;
    width: 100%; 
    height: 85%;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center;
    background-size: contain; 
    opacity: 0.2;
}

.x_body_guides {
    position: absolute;
    bottom: .1rem;
    right: 1rem;
    color: rgb(112, 112, 112);
    cursor: pointer;
    font-size: 15px;
}`, "",{"version":3,"sources":["webpack://./src/views/MainPage/MainPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,aAAa;IACb,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,2EAAiE;IACjE,wBAAwB;IACxB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,WAAW;IACX,yBAAyB;IACzB,eAAe;IACf,eAAe;AACnB","sourcesContent":[".x_body-container {\n    display: flex;\n    flex-wrap: wrap;\n    padding: 1rem;\n    height: auto;\n    max-height: 91vh;\n    overflow-y: scroll;\n}\n\n.x_body-container::before {\n    content: '';\n    position: absolute;\n    width: 100%; \n    height: 85%;\n    background: url('../../assets/FINAL.png') no-repeat center center;\n    background-size: contain; \n    opacity: 0.2;\n}\n\n.x_body_guides {\n    position: absolute;\n    bottom: .1rem;\n    right: 1rem;\n    color: rgb(112, 112, 112);\n    cursor: pointer;\n    font-size: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
