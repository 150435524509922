import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import TableLoader from '../../../components/Loaders/TableLoader'
import SaveChanges from '../../../components/Modals/SaveChanges'
import CancelChanges from '../../../components/Modals/CancelChanges'
import { getUser } from '../../../handlers/users'
import { addUpdateMade, getToolConfig, replaceAllConfigs } from '../../../handlers/configs'
import DdParser_Progress from './Utils/DdParser_Progress'
import { getFunctions } from '../../../handlers/functions'
import DdParser_Tooltip from './Utils/Tooltip'
import ActivityLogs from '../../../components/Modals/ActivityLogs'

import './Configurations.css'

const toolName = 'dd-parser'

export default function DdParser_Config() {
    const [configs, setConfigs] = useState([])
    const [logs, setLogs] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isUploading, setIsUploading] = useState(false)
    const [readOnly, setReadOnly] = useState(true)
    const [toggleUpdate, setToggleUpdate] = useState(false)
    const [inputs, setInputs] = useState({ csddType: 'regular',csddFile: null })
    const [functions, setFunctions] = useState([])
    const [activeConfigIndex, setActiveConfigIndex] = useState()

    const handleSelectChange = (event) => {
        const { value } = event.target
        setInputs(prevState => ({ ...prevState, csddType: value }))
    }

    const handleFileInputChange = (event) => {
        const file = event.target.files[0]
        setInputs(prevState => ({ ...prevState, csddFile: file }))
    }

    const handleCsddUpload = () => {
        console.log('Form submitted with inputs:', inputs)
        
        if (!inputs.csddFile) {
            toast.error('No file selected.')
            return
        } 
        
        setIsLoading(true)
        setIsUploading(true)
        setIsLoading(false)
    }

    const handleConfigure = async() => {
        const _id = localStorage.getItem('id')
        const response = await getUser(_id)

        if(response.role !== 'admin'){
            toast.error('Admin access needed!')
        } else {
            toast.success('Edit enabled!')
            setReadOnly(false)
        }
    }

    const handleGetToolConfig = async () => {
        const response = await getToolConfig(toolName)
        setConfigs(response.configs)
        setLogs(response.updatesMade)
    }

    const handleGetFunctions = async () => {
        const response = await getFunctions()
        const sortedResponse = response.sort((a, b) => a.function.localeCompare(b.function))
        setFunctions(sortedResponse)
    }

    const handleRerenderAll = async() => {
        setIsLoading(true)
        await handleGetToolConfig()
        await handleGetFunctions()
        setIsLoading(false)
    }

    useEffect(() => {
        handleRerenderAll()
    }, [toggleUpdate])

    const getFormattedDate = () => {
        const now = new Date()
        return now.toISOString()
    }

    const handleSaveChanges = async() => {
        const response = await replaceAllConfigs(toolName, configs)
        console.log(response)

        const newUpdate = {
            updatedBy: localStorage.getItem('email'),
            updateNotes: 'Modified Process Configs',
            dateUpdated: getFormattedDate()
        }

        const response2 = await addUpdateMade(toolName, newUpdate)
        console.log(response2)

        setReadOnly(true)
        setToggleUpdate(!toggleUpdate)
        toast.success('Configurations successfully modified')
    }

    const handleCancelChanges = () => {
        setReadOnly(true)
        setToggleUpdate(!toggleUpdate)
    }

    const handleConfigChange = (event, configIndex, actionIndex, key, elementIndex = null) => {
        const { value } = event.target
        setConfigs(prevConfigs => {
            const newConfigs = [...prevConfigs]
            if (elementIndex !== null) {
                newConfigs[configIndex].actions[actionIndex].args[key][elementIndex] = value
            } else if (key) {
                newConfigs[configIndex].actions[actionIndex].args[key] = value
            } else if (actionIndex !== undefined) {
                newConfigs[configIndex].actions[actionIndex][event.target.name] = value
            } else {
                newConfigs[configIndex][event.target.name] = value
            }
            return newConfigs
        })
    }

    const handleFunctionSelectChange = (event, configIndex, actionIndex) => {
        const selectedFunction = event.target.value
        const selectedFunctionData = functions.find(func => func.function === selectedFunction)
    
        const newAction = {
            function: selectedFunction,
            args: { ...selectedFunctionData.args }
        }
    
        setConfigs(prevConfigs => {
            const newConfigs = [...prevConfigs]
            newConfigs[configIndex].actions[actionIndex] = newAction
            return newConfigs
        })
    }

    const handleAddArrayElement = (configIndex, actionIndex, actionKey) => {
        const newConfigs = [...configs]
        newConfigs[configIndex].actions[actionIndex].args[actionKey].push('')
        
        setConfigs(newConfigs)
    }
    
    const handleDeleteArrayElement = (configIndex, actionIndex, actionKey, elementIndex) => {
        const newConfigs = [...configs]
        newConfigs[configIndex].actions[actionIndex].args[actionKey].splice(elementIndex, 1)
        
        setConfigs(newConfigs)
    }
    
    const handleInsertArrayElement = (configIndex, actionIndex, actionKey, elementIndex) => {
        const newConfigs = [...configs]
        newConfigs[configIndex].actions[actionIndex].args[actionKey].splice(elementIndex, 0, '')
        
        setConfigs(newConfigs)
    }

    const handleSetActiveConfig = (configIndex) => {
        configIndex === activeConfigIndex ? setActiveConfigIndex(null) : setActiveConfigIndex(configIndex)
    }

    const handleAddFunction = (configIndex, actionIndex = null) => {
        const newConfigs = [...configs]
        const newFunction = functions[0]
        
        const newAction = {
            function: newFunction.function,
            args: { ...newFunction.args }
        }
        
        if (actionIndex === null) {
            newConfigs[configIndex].actions.push(newAction)
        } else {
            newConfigs[configIndex].actions.splice(actionIndex, 0, newAction)
        }
    
        setConfigs(newConfigs)
    }

    const handleDeleteFunction = (configIndex, actionIndex) => {
        const newConfigs = [...configs]
        newConfigs[configIndex].actions.splice(actionIndex, 1)

        setConfigs(newConfigs)
    }

    const handleDeleteConfig = (configIndex) => {
        const newConfigs = [...configs]
        newConfigs.splice(configIndex, 1)

        setConfigs(newConfigs)
    }
    
    const handleAddNewConfig = () => {
        const newConfig = {
            id: '',
            label: '',
            sheet_name: '',
            actions: []
        }

        setConfigs(prevConfigs => [...prevConfigs, newConfig])
    }

    return (
        <div className={`x_body-container ${isLoading ? 'x_loading' : ''}`}>
            {isLoading ? <TableLoader /> :
                <React.Fragment>
                    <div className='x_card card text-white bg-light mb-3'>
                        <div className='x_card-header card-header'>Upload CS DD File</div>
                        <DdParser_Progress inputs={inputs} uploading={{isUploading, setIsUploading}} toggle={{toggleUpdate, setToggleUpdate}}/>
                        <div className='x_card-body card-body x_template-container'>
                            <select className='x_csDD-select form-select' id='csDD' onChange={handleSelectChange}>
                                <option value='regular'>Regular</option>
                                <option value='canada'>Canada</option>
                                <option value='2p'>2P</option>
                                <option value='3pl'>Inventory</option>
                            </select>
                            <input className='x_csDD-upload form-control' type='file' id='csDD-upload' accept='.xlsx' onChange={handleFileInputChange}/>
                            <button disabled={isUploading} className='x_btn2 x_submit-csDdUpload btn btn-info' onClick={handleCsddUpload}>
                                {isUploading ? <i className='fa-solid fa-spinner fa-spin-pulse'></i> : 'Upload'}
                            </button>
                        </div>
                    </div>
                    {/* <div className='x_card card text-white bg-light mb-3'>
                        <div className='x_card-header card-header'>CS DD List</div>
                        <div className='x_card-body card-body x_template-container2'>
                            <select className='x_csDD-select form-select' id='csDDList' onChange={handleSelectChange}>
                                <option value='regular'>Regular</option>
                                <option value='canada'>Canada</option>
                                <option value='2p'>2P</option>
                                <option value='3pl'>Inventory</option>
                            </select>
                        </div>
                    </div> */}
                    <div className='x_card card text-white bg-light mb-3'>
                        <div className='x_card-header card-header'>Process Configs</div>
                        <div className='x_card-body card-body x_template-container'>
                            {configs.map((config, configIndex) => (
                                <div key={configIndex} className={`x_card2b card`}>
                                    <div className='x_card-header2 card-header x_pointer' onClick={()=>{handleSetActiveConfig(configIndex)}}>
                                        {readOnly ? 
                                            config.label : 
                                            <input type='text' name='label'
                                                value={config.label} 
                                                onClick={(e) => e.stopPropagation()}
                                                onChange={(e) => handleConfigChange(e, configIndex)}
                                            />
                                        }
                                        <span>
                                            <button className={`btn-danger x_btn-delete-config ${readOnly ? 'x_hide-readonly' : ''}`}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    handleDeleteConfig(configIndex)
                                                }}
                                            >
                                                <i className='fa-regular fa-trash-can me-2'></i>
                                                DELETE
                                            </button>
                                            <i className={`fa-solid fa-angle-${activeConfigIndex === configIndex ? 'up' : 'down'}`}></i>
                                        </span>
                                    </div>
                                    <div className={`x_card-body x_card-body_ddconfig card-body ${activeConfigIndex === configIndex ? 'x_card-body_ddconfig-active' : 'x_hide-readonly'}`}>
                                        <div className='x_idsheetName_container'>
                                            <div className='x_configId'>
                                                <span>ID: </span>
                                                <input type='text' name='id' 
                                                    disabled={readOnly} 
                                                    value={config.id} 
                                                    onChange={(e) => handleConfigChange(e, configIndex)}
                                                />
                                            </div>
                                            <div className='x_configSheetName'>
                                                <span>Sheet Name: </span>
                                                <input type='text' name='sheet_name'
                                                    disabled={readOnly} 
                                                    value={config.sheet_name} 
                                                    onChange={(e) => handleConfigChange(e, configIndex)}
                                                />
                                            </div>
                                        </div>
                                        <div className='x_configActions_container'>
                                            <div>Actions:</div>
                                            {config.actions.map((action, actionIndex) => (
                                                <React.Fragment key={actionIndex}>
                                                    <button className={`btn-success x_btn-add-function ${readOnly ? 'x_hide-readonly' : ''}`}
                                                        onClick={() => handleAddFunction(configIndex, actionIndex)}
                                                    >
                                                        Add Function Here
                                                        <i className='fa-solid fa-arrow-right ms-2'></i>
                                                    </button>
                                                    <div key={actionIndex} className='x_action-container'>
                                                        <label className='x_label form-label'>
                                                            Function:
                                                            <button className={`btn-danger x_btn-delete-function pe-4 ps-4 ${readOnly ? 'x_hide-readonly' : ''}`}
                                                                onClick={()=>{handleDeleteFunction(configIndex, actionIndex)}}
                                                            >
                                                                <i className='fa-regular fa-trash-can me-2'></i>
                                                                DELETE
                                                            </button>
                                                        </label>
                                                        <select name='function'
                                                            className='x_form-select x_form-select-function form-select' 
                                                            disabled={readOnly}
                                                            value={action.function} 
                                                            onChange={(e) => handleFunctionSelectChange(e, configIndex, actionIndex)}
                                                        >
                                                            {functions.map((func, funcIndex) => (
                                                                <option key={funcIndex} value={func.function}>{func.function}</option>
                                                            ))}
                                                        </select>
                                                        <label className='x_label form-label'>Parameters:</label>
                                                        <div className='x_parameters-container'>
                                                            <table className='x_table x_table-parameters table table-hover'>
                                                                <thead className='x_thead'>
                                                                    <tr style={{width: '100%'}}>
                                                                        <th style={{width: '30%'}}>Parameter</th>
                                                                        <th style={{width: '70%'}}>Value</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {Object.entries(action.args).map(([actionKey, actionValue], paramIndex) => (
                                                                        <tr key={paramIndex}>
                                                                            <td key={`${paramIndex}-col1`}>
                                                                                <DdParser_Tooltip paramIndex={`${paramIndex}-col1`} func={action.function} parameter={actionKey}/>
                                                                            </td>
                                                                            <td key={`${paramIndex}-col2`}>
                                                                                {readOnly ? 
                                                                                    !Array.isArray(actionValue) ? actionValue : actionValue.join(' | ') : 
                                                                                    !Array.isArray(actionValue) ?
                                                                                        <input type='text'
                                                                                            className='x_table-parameters-value' 
                                                                                            name={actionKey}
                                                                                            value={actionValue}
                                                                                            onChange={(e) => handleConfigChange(e, configIndex, actionIndex, actionKey)}
                                                                                        /> :
                                                                                        <React.Fragment>
                                                                                            {actionValue.map((element, elementIndex) => (
                                                                                                <div key={elementIndex} className='x_table-parameters-value2-container'>
                                                                                                    <button className='btn-success x-add-val_btn' onClick={() => {handleInsertArrayElement(configIndex, actionIndex, actionKey, elementIndex)}}>
                                                                                                        <i className='fa-solid fa-plus'></i>
                                                                                                    </button>
                                                                                                    <label className='x_elementIndex-label'>{elementIndex+1}</label>
                                                                                                    <input type='text' key={elementIndex}
                                                                                                        name={actionKey}
                                                                                                        value={element}
                                                                                                        onChange={(e) => handleConfigChange(e, configIndex, actionIndex, actionKey, elementIndex)}
                                                                                                    />
                                                                                                    <button className='btn-danger x-delete-val_btn' onClick={() => {handleDeleteArrayElement(configIndex, actionIndex, actionKey, elementIndex)}}>
                                                                                                        <i className='fa-solid fa-minus'></i>
                                                                                                    </button>
                                                                                                </div>
                                                                                            ))}
                                                                                            <button className='btn-success x-add-val_btn' onClick={() => {handleAddArrayElement(configIndex, actionIndex, actionKey)}}>
                                                                                                <i className='fa-solid fa-plus'></i>
                                                                                            </button>
                                                                                        </React.Fragment>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            ))}
                                            <button className={`btn-success x_btn-add-function2 ${readOnly ? 'x_hide-readonly' : ''}`}
                                                onClick={() => handleAddFunction(configIndex)}
                                            >
                                                Add Function Here
                                                <i className='fa-solid fa-arrow-right ms-2'></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <button className={`btn btn-outline-success x_btn-add-config ${readOnly ? 'x_hide-readonly' : ''}`}
                                onClick={handleAddNewConfig}
                            >
                                Add New Config
                            </button>
                        </div>
                    </div>
                    {readOnly ? 
                        <div className='x_actionButtons'>
                            <button className='btn btn-info' onClick={handleConfigure}>
                                <i className='fa-solid fa-gears'/> Configure
                            </button>
                            <ActivityLogs logs={logs}/>
                        </div> :
                        <div className='x_actionButtons'>
                            <SaveChanges handleSaveChanges={handleSaveChanges}/>
                            <CancelChanges handleCancelChanges={handleCancelChanges}/>
                        </div>
                    }
                </React.Fragment>
            }
        </div>
    )
}