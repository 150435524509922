import React, { useEffect, useRef, useState } from 'react'

import { getToolConfig } from '../../../handlers/configs'
import TableLoader from '../../../components/Loaders/TableLoader'

import './Configurations.css'

const toolName = 'legacy-v2'

export default function LegacyV2_Config() {
    const [configs, setConfigs] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const fileInputRef = useRef(null)

    const handleGetToolConfig = async () => {
        setIsLoading(true)

        const response = await getToolConfig(toolName)
        setConfigs(response.configs)

        setIsLoading(false)
    }

    useEffect(() => {
        handleGetToolConfig()
    }, [])

    const handleDownload = (key) => {
        const downloadUrl = `https://demand-data-tools.s3.amazonaws.com/${key}`
        const anchor = document.createElement('a')
        anchor.href = downloadUrl
        anchor.setAttribute('download', '')
        anchor.click()
    }

    return (
        <div className={`x_body-container ${isLoading ? 'x_loading' : ''}`}>
            {isLoading ? <TableLoader /> :
                <React.Fragment>
                    <div className='x_card card text-white bg-light mb-3'>
                        <div className='x_card-header card-header'>Import Templates</div>
                        <div className='x_card-body card-body x_template-container'>
                            {configs.filter(config => config.template_location).map((config, configIndex) => (
                                <div key={configIndex} className='x_template text-dark'>
                                    <div className='mb-3'>
                                        <span>{config.name.replace(' Template','')}</span>
                                        <button onClick={()=>handleDownload(config.template_location)} className='x_download-template btn btn-sm btn-light'>Download template</button>
                                        <input type='file' id={`templateInput${configIndex}`} style={{display: 'none'}} ref={fileInputRef} />
                                        {/* <button disabled={readOnly} className='x_download-template btn btn-sm btn-light' onClick={()=>handleButtonClick(config.name)}>Upload template</button> */}
                                    </div>
                                    <div className='x_location badge bg-info'>{config.template_location.replace('templates/','')}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </React.Fragment>
            }
        </div>
    )
}