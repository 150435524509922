import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'

import './Modals.css'
import { toast } from 'react-toastify'

export default function DdParserSetup({ inputs, handleParse, isLoadingCsdd, loading, sheetsConfigs }) {
    const [modalIsOpen, setIsOpen] = useState(false)
    const [checkedItems, setCheckedItems] = useState({})
    const [selectAll, setSelectAll] = useState(false)
    const [disableParseButton, setDisableParseButton] = useState(true)

    useEffect(() => {
        const anyCheckboxSelected = Object.values(checkedItems).some((value) => value)
        setDisableParseButton(!anyCheckboxSelected)
    }, [checkedItems])

    useEffect(() => {
        const initialCheckedState = {}
        sheetsConfigs.forEach((checkbox) => {
            initialCheckedState[checkbox.id] = false
        })
        setCheckedItems(initialCheckedState)
    }, [sheetsConfigs])

    const openModal = () => {
        if (!inputs.ddFile) {
            toast.error('No file selected.')
            return
        } 

        setIsOpen(true)
        console.log(inputs)
    }

    const closeModal = () => {
        setIsOpen(false)
        const initialCheckedState = {}
        sheetsConfigs.forEach((checkbox) => {
            initialCheckedState[checkbox.id] = false
        })
        setCheckedItems(initialCheckedState)
        setSelectAll(false)
    }

    const handleYes = () => {
        const selectedHeaders = Object.keys(checkedItems).filter(key => checkedItems[key])
        const filteredSheetsConfigs = sheetsConfigs.filter(sheet => selectedHeaders.includes(sheet.id))
        handleParse(filteredSheetsConfigs)
        closeModal()
    }

    const handleCheckboxChange = (id) => {
        setCheckedItems((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }))
    }

    const handleSelectAllChange = () => {
        const newSelectAll = !selectAll
        setSelectAll(newSelectAll)
        const newCheckedItems = {}
        sheetsConfigs.forEach((checkbox) => {
            newCheckedItems[checkbox.id] = newSelectAll
        })
        setCheckedItems(newCheckedItems)
    }

    return (
        <div className='x_btn-container'>
            <button className='x_btn2 btn btn-sm btn-info' onClick={openModal} disabled={isLoadingCsdd || loading.isLoading}>
                {isLoadingCsdd || loading.isLoading ? <i className="fa-solid fa-spinner fa-spin-pulse"></i> : 'Parse'}
            </button>
            <Modal
                className='x_saveModal x_ddParserModal'
                isOpen={modalIsOpen}
                onAfterOpen={() => {}}
                onRequestClose={closeModal}
                shouldCloseOnEsc={false}
                shouldCloseOnOverlayClick={false}
                ariaHideApp={false}
            >
                <h4>Process Setup:</h4>
                <div>Please select the column/s that you want to parse</div>
                <div
                    className={`x_checkbox-all form-check ${selectAll ? 'x_checkbox-active' : 'x_checkbox-all'}`}
                    onClick={handleSelectAllChange}
                >
                    <input
                        className='x_checkbox-input form-check-input'
                        type='checkbox'
                        checked={selectAll}
                        onChange={handleSelectAllChange}
                    />
                    <label className='x_checkbox-label form-check-label'>Select All</label>
                </div>
                <div className='x_checkbox-container'>
                    {sheetsConfigs.map((checkbox, index) => (
                        <div
                            key={index}
                            className={`x_checkbox form-check ${checkedItems[checkbox.id] ? 'x_checkbox-active' : ''}`}
                            onClick={() => handleCheckboxChange(checkbox.id)}
                        >
                            <input
                                type='checkbox'
                                className='x_checkbox-input form-check-input'
                                checked={checkedItems[checkbox.id]}
                                onChange={() => handleCheckboxChange(checkbox.id)}
                            />
                            <label className='x_checkbox-label form-check-label'>{checkbox.label}</label>
                        </div>
                    ))}
                </div>
                <div className='x_buttonsContainer'>
                    <button className='btn btn-outline-danger x_cancelButton' onClick={closeModal}>
                        Cancel
                    </button>
                    <button disabled={disableParseButton} className='btn btn-info x_yesButton' onClick={handleYes}>
                        Parse
                    </button>
                </div>
            </Modal>
        </div>
    )
}