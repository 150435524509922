// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
    color: #ecf0f1;
    font-size: 17px;
    background-color: rgb(223,105,25);
    border: 1px solid #f39c12;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px;
    box-shadow: 0px 6px 0px #d35400;
    transition: all 0.1s;
}

.button:active {
    box-shadow: 0px 2px 0px #d35400;
    position: relative;
    top: 2px;
}`, "",{"version":3,"sources":["webpack://./src/views/PageNotFound/PageNotFound.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,iCAAiC;IACjC,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,aAAa;IACb,+BAA+B;IAC/B,oBAAoB;AACxB;;AAEA;IACI,+BAA+B;IAC/B,kBAAkB;IAClB,QAAQ;AACZ","sourcesContent":[".button {\n    color: #ecf0f1;\n    font-size: 17px;\n    background-color: rgb(223,105,25);\n    border: 1px solid #f39c12;\n    border-radius: 5px;\n    cursor: pointer;\n    padding: 10px;\n    box-shadow: 0px 6px 0px #d35400;\n    transition: all 0.1s;\n}\n\n.button:active {\n    box-shadow: 0px 2px 0px #d35400;\n    position: relative;\n    top: 2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
