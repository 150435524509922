import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { uploadFileToS3 } from '../../../middlewares/AWS'
import { addDownload } from '../../../handlers/downloads'
import { useNavigate } from 'react-router-dom'

import './Tools.css'
import { initializeWorker } from '../../../handlers/workers'

export default function TemplateParser() {
    const isQA = process.env.REACT_APP_NODE_ENV === 'development'
    const navigate = useNavigate()

    const [isLoading, setIsloading] = useState(false)
    const [inputs, setInputs] = useState({
        sourceType: 'Data Central',
        ddType: 'Regular DD',
        ddFile: null,
        importName: ''
    })

    const handleSelectChange = (event) => {
        const { id, value } = event.target
        setInputs(prevState => ({ ...prevState, [id]: value }))
    }

    const handleFileInputChange = (event) => {
        const file = event.target.files[0]
        setInputs(prevState => ({ ...prevState, ddFile: file }))
    }

    const handleTextInputChange = (event) => {
        const { id, value } = event.target
        setInputs(prevState => ({ ...prevState, [id]: value }))
    }

    const handleSubmit = async () => {
        console.log('Form submitted with inputs:', inputs)
        setIsloading(true)

        if (!inputs.ddFile) {
            toast.error('No file selected.')
            setIsloading(false)
            return
        } 
        
        const batchId = `DDT-${Date.now()}${isQA ? ' (QA)' : ''}`
        const keyName = await uploadFileToS3('imports', inputs.ddFile, `(${batchId})${inputs.ddFile.name}`)
        const requestBody = {
            batchId,
            category: 'template-parser',
            importName: inputs.importName,
            user: localStorage.getItem('email'),
            originalFile: keyName,
            options: inputs,
        }

        const response = await addDownload(requestBody)
        await initializeWorker(response.download)
        navigate('/downloads')

        toast.success('Submitted!')
        setIsloading(false)
    }

    return (
        <React.Fragment>
            <div className='x_card-body x_card-body2b card-body'>
                <div className='x_select-mainContainer'>
                    <div className='x_select-container'>
                        <label className='x_label form-label'>Source Type:</label>
                        <select className='x_form-select form-select' id='sourceType' onChange={handleSelectChange} value={inputs.sourceType}>
                            <option value='Data Central'>Data Central</option>
                            <option value='Legacy' disabled>Legacy</option>
                        </select>
                    </div>
                    <div className='x_select-container'>
                        <label className='x_label form-label'>DD Type:</label>
                        <select className='x_form-select form-select' id='ddType' onChange={handleSelectChange} value={inputs.ddType}>
                            <option value='Regular DD'>Regular DD</option>
                            <option value='SP DD'>SP DD</option>
                            <option value='2P DD'>2P DD</option>
                        </select>
                    </div>
                </div>
                <div className='x_fileInput-container'>
                    <label className='x_label form-label'>DD File:</label>
                    <input className='x_fileInput form-control' type='file' id='fileInput1' accept='.xlsb, .xlsx' onChange={handleFileInputChange} />
                </div>
                <div className='x_textInput-container'>
                    <label className='x_label form-label'>Import Name: </label>
                    <input type='text' className='x_outputFile form-control' placeholder='Please input desired file name' id='importName' onChange={handleTextInputChange} />
                </div>
            </div>
            <div className='x_card-footer card-footer'>
                <button id='parseButton1' className='x_btn btn btn-primary btn-sm' 
                    onClick={handleSubmit}
                    disabled={isLoading ? true : false}
                >{isLoading ? <i className="fa-solid fa-spinner fa-spin-pulse"></i> : 'Generate'}
                </button>
            </div>
        </React.Fragment>
    )
}