import React from 'react'
import { useNavigate } from 'react-router-dom'
import gifBackground from '../../assets/page-not-found-meme.gif'
import './PageNotFound.css'

export default function PageNotFound() {
    const navigate = useNavigate()
    const redirect = () => navigate('/main-page')

    return (
        <div style={{ backgroundImage: `url(${gifBackground})`, backgroundSize: 'cover' , height: '100vh', textAlign: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',height: '100vh' }}>
                <button onClick={redirect} className='button'>Go back to Main Page</button>
            </div>
        </div>
    )
}