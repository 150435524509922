const proxyUrl = process.env.REACT_APP_NODE_ENV === 'development' ? 'http://localhost:5000' : process.env.REACT_APP_BACKEND_BASE_URL

async function makeApiRequest(url, options) {
    try {
        const response = await fetch(url, options)
        
        if (response.ok) {
            return response.json()
        } else {
            return new Error(`Request failed with status: ${response.statusText}`)
        }

    } catch (error) {
        console.error('Error fetching data:', error.message)
        return error
    }
}

module.exports = {
    makeApiRequest, proxyUrl
}