import React, { useState, useEffect } from 'react'
import { ProgressBar } from 'react-step-progress-bar'
import { uploadFileToS3 } from '../../../../middlewares/AWS'
import { toast } from 'react-toastify'
import { addUpdateMade } from '../../../../handlers/configs'

import 'react-step-progress-bar/styles.css'
import './Utils.css'

export default function DdParser_Progress({ inputs, uploading, toggle }) {
    const [uploadProgress, setUploadProgress] = useState(0)

    useEffect(() => {
        if (uploading.isUploading) {
            handleUploadCsdd()
        }
    }, [uploading.isUploading])

    const getFormattedDate = () => {
        const now = new Date()
        return now.toISOString()
    }

    const handleUploadCsdd = async () => {
        try {
            const mainFolderPath = `cs-dd/${inputs.csddType}`
            const folderPath = await uploadFileToS3(mainFolderPath, inputs.csddFile ,inputs.csddFile.name)
            console.log({folderPath})

            const newUpdate = {
                updatedBy: localStorage.getItem('email'),
                updateNotes: `Uploaded ${inputs.csddType} CSDD: ${inputs.csddFile.name}`,
                dateUpdated: getFormattedDate()
            }
    
            const response = await addUpdateMade('dd-parser', newUpdate)
            console.log({response})
        
            toast.success('CS DD successfully uploaded!')
            toggle.setToggleUpdate(!toggle.toggleUpdate)

        } catch (error) {
            console.log(error)
            toast.error('Error uploading file!')
        }

        setUploadProgress(0)
        uploading.setIsUploading(false)
    }

    return (
        uploading.isUploading ? (
            <React.Fragment>
                <ProgressBar
                    percent={uploadProgress}
                    filledBackground='linear-gradient(to right, #7dca9f, #71dbb2)'
                    text='uploading...'
                />
            </React.Fragment>
        ) : null
    )
}