import React, { useState } from 'react'
import './Utils.css'

export default function DdParser_Tooltip({ paramIndex, func, parameter }) {
    const functionInfo = tableOfContents.find(item => item.function === func)
    const parameterDescription = functionInfo ? functionInfo.args[parameter] : ''

    const [isHovered, setIsHovered] = useState(false)

    return (
        <React.Fragment key={paramIndex}>
            <div className='container'>
                <div className='content'
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    {parameter}
                </div>
                <div className={`tooltip ${isHovered ? 'show' : ''}`}>
                    {parameterDescription}
                </div>
            </div>
        </React.Fragment>
    )
}

const tableOfContents = [
    {
        function: 'yes_no_lookup',
        args: {
            left_col: 
                <div>
                    <div><b>Column header</b> in the DD Report that serves as the <b>lookup value</b></div>
                    <b>Example: </b>
                    <div>=IF( ISNA( LOOKUP( <b>left_col</b>, right_col, right_col ) ), yes_value, no_value )</div>
                    <div>=IF( ISNA( LOOKUP( <b>A2</b>, B:B, C:C ) ), "Yes", "No" )</div>
                </div>,
            right_col: 
                <div>
                    <div><b>Column header</b> in the CS DD that serves as the <b>lookup range</b></div>
                    <b>Example: </b>
                    <div>=IF( ISNA( LOOKUP( left_col, <b>right_col</b>, right_col ) ), yes_value, no_value )</div>
                    <div>=IF( ISNA( LOOKUP( A2, <b>B:B</b>, C:C ) ), "Yes", "No" )</div>
                </div>,
            new_col_name: 
                <div>
                    <div><b>Column header</b> in the DD Report where the <b>result</b> will be outputted</div>
                </div>,
            yes_value: 
                <div>
                    <div>Value to use if <b>found</b></div>
                    <b>Example: </b>
                    <div>=IF( ISNA( LOOKUP( left_col, right_col, right_col ) ), <b>yes_value</b>, no_value )</div>
                    <div>=IF( ISNA( LOOKUP( A2, B:B, C:C ) ), <b>"Yes"</b>, "No" )</div>
                </div>,
            no_value: 
                <div>
                    <div>Value to use if not found</div>
                    <b>Example: </b>
                    <div>=IF( ISNA( LOOKUP( left_col, right_col, right_col ) ), yes_value, <b>no_value</b> )</div>
                    <div>=IF( ISNA( LOOKUP( A2, B:B, C:C ) ), "Yes", <b>"No"</b> )</div>
                </div>,
        },
    },
    {
        function: 'lookup',
        args: {
            left_col: 
                <div>
                    <div><b>Column header</b> in the DD Report that serves as the <b>lookup value</b></div>
                    <b>Example: </b>
                    <div>=IFNA( LOOKUP( <b>left_col</b>, right_col, right_cols ), na_value )</div>
                    <div>=IFNA( LOOKUP( <b>A2</b>, B:B, [C:C, D:D, ...] ), "n/a" )</div>
                </div>,
            right_col: 
                <div>
                    <div><b>Column header</b> in the CS DD that serves as the <b>lookup range</b></div>
                    <b>Example: </b>
                    <div>=IFNA( LOOKUP( left_col, <b>right_col</b>, right_cols ), na_value )</div>
                    <div>=IFNA( LOOKUP( A2, <b>B:B</b>, [C:C, D:D, ...] ), "n/a" )</div>
                </div>,
            new_col_names: 
                <div>
                    <div><b>Column header/s</b> in the DD Report where the result/s will be <b>outputted</b></div>
                    <div>* Should be the <b>same number</b> of items in <b>right_cols</b></div>
                </div>,
            right_cols: 
                <div>
                    <div><b>Column header/s</b> in the CS DD that serves as the <b>result range</b></div>
                    <b>Example: </b>
                    <div>=IFNA( LOOKUP( left_col, right_col, <b>right_cols</b> ), na_value )</div>
                    <div>=IFNA( LOOKUP( A2, B:B, <b>[C:C, D:D, ...]</b> ), "n/a" )</div>
                </div>,
            na_value: 
                <div>
                    <div>Value to use if <b>not found</b></div>
                    <b>Example: </b>
                    <div>=IFNA( LOOKUP( left_col, right_col, right_cols ), <b>na_value</b> )</div>
                    <div>=IFNA( LOOKUP( A2, B:B, [C:C, D:D, ...] ), <b>"n/a"</b> )</div>
                </div>,
        },
    },
    {
        function: 'sumif',
        args: {
            left_col: 
                <div>
                    <div><b>Column header</b> in the DD Report that serves as the <b>criteria value</b></div>
                    <b>Example: </b>
                    <div>=SUMIF( right_col, <b>left_col</b>, sum_columns )</div>
                    <div>=SUMIF( A:A, <b>A2</b>, [B:B, C:C, ...] )</div>
                </div>,
            right_col: 
                <div>
                    <div><b>Column header</b> in the CS DD that serves as the <b>lookup range</b> against the left_col</div>
                    <b>Example: </b>
                    <div>=SUMIF( <b>right_col</b>, left_col, sum_columns )</div>
                    <div>=SUMIF( <b>A:A</b>, A2, [B:B, C:C, ...] )</div>
                </div>,
            sum_columns: 
                <div>
                    <div><b>Column header/s</b> in the CS DD that serves as the <b>sum range</b></div>
                    <b>Example: </b>
                    <div>=SUMIF( right_col, left_col, <b>sum_columns</b> )</div>
                    <div>=SUMIF( A:A, A2, <b>[B:B, C:C, ...]</b> )</div>
                </div>,
            new_columns: 
                <div>
                    <div><b>Column header/s</b> in the DD Report where the result/s will be <b>outputted</b></div>
                    <div>* Should be the <b>same number</b> of items in <b>sum_columns</b></div>
                </div>,
        },
    },
    {
        function: 'concatenate_columns',
        args: {
            columns_to_concatenate: 
                <div>
                    <div>List of DD Report <b>column headers</b> that will be <b>concatenated</b></div>
                    <b>Example: </b>
                    <div>=CONCATENATE(<b>columns_to_concatenate</b>)</div>
                    <div>=CONCATENATE( <b>[A2, B2, ...]</b> )</div>
                </div>,
            new_column_name: 
                <div>
                    <div>Desired DD report <b>column header label</b> to use for the <b>output</b></div>
                    <div>* Column header to use should be <b>unique</b>. Using an existing column header will <b>overwrite</b> data under that column</div>
                    <div>* Results column for this function are just temporary. They are deleted after the entire parsing process</div>
                </div>,
        },
    },
    {
        function: 'parse_listing_status',
        args: {
            output_column: 
                <div>
                    <div><b>Column header</b> in the DD Report where the final listing status be <b>outputted</b></div>
                </div>,
        },
    },
    {
        function: 'count_if',
        args: {
            column_to_count: 
                <div>
                    <div><b>Column header</b> in the DD Report that needs to implement <b>countif</b> formula</div>
                    <b>Example: </b>
                    <div>=COUNTIF( <b>column_to_count</b>, column_to_count(Cell-Row) )</div>
                    <div>=COUNTIF( <b>A:A</b>, A1 )</div>
                </div>,
            output_column: 
                <div>
                    <div><b>Column header</b> in the DD Report where the result will be <b>outputted</b></div>
                    <div>* Column header to use should be <b>unique</b>. Using an existing column header will <b>overwrite</b> data under that column</div>
                </div>,
        },
    },
    {
        function: 'is_equal',
        args: {
            column1: 
                <div>
                    <div><b>Column header</b> in the DD Report <b>to compare</b> with column2</div>
                    <b>Example: </b>
                    <div>=<b>column1</b>=column2</div>
                    <div>=<b>A1</b>=B1</div>
                </div>,
            column2: 
                <div>
                    <div><b>Column header</b> in the DD Report to <b>compare</b> with column1</div>
                    <b>Example: </b>
                    <div>=column1=<b>column2</b></div>
                    <div>=A1=<b>B1</b></div>
                </div>,
            output_column: 
                <div>
                    <div><b>Column header</b> in the DD Report where the result will be <b>outputted</b></div>
                </div>,
        },
    },
    {
        function: 'if_sumif',
        args: {
            left_col1: 
                <div>
                    <div><b>Column header</b> in the DD Report that serves as the <b>first lookup value</b></div>
                    <b>Example: </b>
                    <div>=IF( condition_col = 1, SUMIF( right_col1, <b>left_col1</b>, sum_columns ), SUMIF( right_col2, left_col2, sum_columns ) )</div>
                    <div>=IF( B2 = 1, SUMIF( A:A, <b>A2</b>, [C:C, D:D, ...] ), SUMIF( B:B, B2, [C:C, D:D, ...] ) )</div>
                </div>,
            right_col1: 
                <div>
                    <div><b>Column header</b> in the CS DD that serves as the <b>first lookup range</b></div>
                    <b>Example: </b>
                    <div>=IF( condition_col = 1, SUMIF( <b>right_col1</b>, left_col1, sum_columns ), SUMIF( right_col2, left_col2, sum_columns ) )</div>
                    <div>=IF( B2 = 1, SUMIF( <b>A:A</b>, A2, [C:C, D:D, ...] ), SUMIF( B:B, B2, [C:C, D:D, ...] ) )</div>
                </div>,
            left_col2: 
                <div>
                    <div><b>Column header</b> in the DD Report that serves as the <b>second lookup value</b></div>
                    <b>Example: </b>
                    <div>=IF( condition_col = 1, SUMIF( right_col1, left_col1, sum_columns ), SUMIF( right_col2, <b>left_col2</b>, sum_columns ) )</div>
                    <div>=IF( B2 = 1, SUMIF( A:A, A2, [C:C, D:D, ...] ), SUMIF( B:B, <b>B2</b>, [C:C, D:D, ...] ) )</div>
                </div>,
            right_col2: 
                <div>
                    <div><b>Column header</b> in the CS DD that serves as the <b>second lookup range</b></div>
                    <b>Example: </b>
                    <div>=IF( condition_col = 1, SUMIF( right_col1, left_col1, sum_columns ), SUMIF( <b>right_col2</b>, left_col2, sum_columns ) )</div>
                    <div>=IF( B2 = 1, SUMIF( A:A, A2, [C:C, D:D, ...] ), SUMIF( <b>B:B</b>, B2, [C:C, D:D, ...] ) )</div>
                </div>,
            condition_col: 
                <div>
                    <div><b>Column header</b> in the DD Report that serves as the <b>basis on what lookup range and value to use</b> between 1 and 2</div>
                    <b>Example: </b>
                    <div>=IF( <b>condition_col</b> = 1, SUMIF( right_col1, left_col1, sum_columns ), SUMIF( right_col2, left_col2, sum_columns ) )</div>
                    <div>=IF( <b>B2</b> = 1, SUMIF( A:A, A2, [C:C, D:D, ...] ), SUMIF( B:B, B2, [C:C, D:D, ...] ) )</div>
                </div>,
            sum_columns: 
                <div>
                    <div><b>Column header/s</b> in the CS DD that serves as the <b>sum range</b></div>
                    <b>Example: </b>
                    <div>=IF( condition_col = 1, SUMIF( right_col1, left_col1, <b>sum_columns</b> ), SUMIF( right_col2, left_col2, <b>sum_columns</b> ) )</div>
                    <div>=IF( B2 = 1, SUMIF( A:A, A2, <b>[C:C, D:D, ...]</b> ), SUMIF( B:B, B2, <b>[C:C, D:D, ...]</b> ) )</div>
                </div>,
            new_columns: 
                <div>
                    <div><b>Column header/s</b> in the DD Report where the result/s will be <b>outputted</b></div>
                    <div>* Should be the <b>same number</b> of items in <b>sum_columns</b></div>
                </div>,
        },
    },
]