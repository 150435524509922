import React from 'react'
import './Layout.css'

export default function Footer() {
    return (
        <nav className='x_footer navbar navbar-expand-lg bg-dark' data-bs-theme='dark'>
            Copyright &copy; 2024. Designed by Sczali C. Developed by IT Dev PH.
            <a className='x_body_guides' 
                href='https://docs.google.com/document/d/1zMzKSup8c2QgthXZZvaegUsVeynKfdnzsrb4SGZ672I/edit' 
                target='_blank' 
                rel='noopener noreferrer'>
                <span>Read Guides, Documentation, and Changelogs here </span>
                <i className='fa-solid fa-arrow-up-right-from-square'></i>
            </a>
        </nav>
    )
}