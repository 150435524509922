import React, { useEffect, useRef, useState } from 'react'
import { WithContext as ReactTags } from 'react-tag-input'
import { toast } from 'react-toastify'

import { addUpdateMade, getToolConfig, replaceAllConfigs, replaceConfig } from '../../../handlers/configs'
import TableLoader from '../../../components/Loaders/TableLoader'
import SaveChanges from '../../../components/Modals/SaveChanges'
import CancelChanges from '../../../components/Modals/CancelChanges'
import { getUser } from '../../../handlers/users'
import { uploadFileToS3 } from '../../../middlewares/AWS'
import ActivityLogs from '../../../components/Modals/ActivityLogs'

import './Configurations.css'

const toolName = 'template-parser'

export default function TemplateParser_Config() {
    const [configs, setConfigs] = useState([])
    const [logs, setLogs] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [readOnly, setReadOnly] = useState(true)
    const [toggleUpdate, setToggleUpdate] = useState(false)
    const fileInputRef = useRef(null)

    const handleButtonClick = async (name) => {
        fileInputRef.current.click()
    
        await new Promise((resolve) => {
            fileInputRef.current.addEventListener('change', resolve, { once: true })
        })
    
        const file = fileInputRef.current.files[0]
        setIsLoading(true)
    
        if (file && file.name.endsWith('.xlsx')) {
            const configName = name
            const newConfigs = [...configs]
            const configIndex = newConfigs.findIndex(config => config.name === configName)

            if (configIndex !== -1) {
                const folderPath = await uploadFileToS3('templates', file , file.name)
                console.log({folderPath})

                const response = await replaceConfig(toolName, name, {name, template_location: `templates/${file.name}` })
                console.log(response)

                const newUpdate = {
                    updatedBy: localStorage.getItem('email'),
                    updateNotes: `Uploaded ${name}: ${file.name}`,
                    dateUpdated: getFormattedDate()
                }
        
                const response2 = await addUpdateMade(toolName, newUpdate)
                console.log(response2)

                setToggleUpdate(!toggleUpdate)
                setReadOnly(true)
                toast.success(`Configurations under '${configName}' successfully updated`)
            } else {
                toast.error(`Config with name '${configName}' not found`)
            }
        } else {
            toast.error('Please select a valid .xlsx file')
        }
        setIsLoading(false)
    }

    const handleGetToolConfig = async () => {
        setIsLoading(true)

        const response = await getToolConfig(toolName)
        setConfigs(response.configs)
        setLogs(response.updatesMade)

        setIsLoading(false)
    }

    useEffect(() => {
        handleGetToolConfig()
    }, [toggleUpdate])

    const handleDelete = (configIndex, tagIndex) => {
        const newConfigs = [...configs]
        newConfigs[configIndex].headers.splice(tagIndex, 1)
        setConfigs(newConfigs)
    }

    const handleAddition = (configIndex, tag) => {
        const newConfigs = [...configs]
        const headerExists = newConfigs[configIndex].headers.includes(tag.text)
        if (!headerExists) {
            newConfigs[configIndex].headers.push(tag.text)
            setConfigs(newConfigs)
        } else {
            toast.error('Header is already existing!')
        }
    }

    const handleDrag = (configIndex, tag, currPos, newPos) => {
        const newConfigs = [...configs]
        const configHeaders = newConfigs[configIndex].headers
        const [removed] = configHeaders.splice(currPos, 1)
        configHeaders.splice(newPos, 0, removed)
        setConfigs(newConfigs)
    }

    const handleClearAll = (configIndex) => {
        const newConfigs = [...configs]
        newConfigs[configIndex].headers = []
        setConfigs(newConfigs)
    }

    const handleTagUpdate = (configIndex, i, newTag) => {
        const newConfigs = [...configs]
        newConfigs[configIndex].headers[i] = newTag.text
        setConfigs(newConfigs)
    }

    const handleDownload = (key) => {
        const downloadUrl = `https://demand-data-tools.s3.amazonaws.com/${key}`
        const anchor = document.createElement('a')
        anchor.href = downloadUrl
        anchor.setAttribute('download', '')
        anchor.click()
    }

    const handleConfigure = async() => {
        const _id = localStorage.getItem('id')
        const response = await getUser(_id)

        if(response.role !== 'admin'){
            toast.error('Admin access needed!')
        } else {
            toast.success('Edit enabled!')
            setReadOnly(false)
        }
    }

    const getFormattedDate = () => {
        const now = new Date()
        return now.toISOString()
    }

    const handleSaveChanges = async() => {
        const response = await replaceAllConfigs(toolName, configs)
        console.log(response)

        const newUpdate = {
            updatedBy: localStorage.getItem('email'),
            updateNotes: 'Modified Process Configs',
            dateUpdated: getFormattedDate()
        }

        const response2 = await addUpdateMade(toolName, newUpdate)
        console.log(response2)

        setReadOnly(true)
        setToggleUpdate(!toggleUpdate)
        toast.success('Configurations successfully modified')
    }

    const handleCancelChanges = () => {
        setReadOnly(true)
        setToggleUpdate(!toggleUpdate)
    }

    return (
        <div className={`x_body-container ${isLoading ? 'x_loading' : ''}`}>
            {isLoading ? <TableLoader /> :
                <React.Fragment>
                    <div className='x_card card text-white bg-light mb-3'>
                        <div className='x_card-header card-header'>DD Templates</div>
                        <div className='x_card-body card-body x_template-container'>
                            {configs.filter(config => config.template_location).map((config, configIndex) => (
                                <div key={configIndex} className='x_template text-dark'>
                                    <div className='mb-3'>
                                        <span>{config.name.replace(' Template','')}</span>
                                        <button onClick={()=>handleDownload(config.template_location)} className='x_download-template btn btn-sm btn-light'>Download template</button>
                                        <input type='file' id={`templateInput${configIndex}`} style={{display: 'none'}} ref={fileInputRef} />
                                        <button disabled={readOnly} className='x_download-template btn btn-sm btn-light' onClick={()=>handleButtonClick(config.name)}>Upload template</button>
                                    </div>
                                    <div className='x_location badge bg-info'>{config.template_location.replace('templates/','')}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {configs.filter(config => config.headers).map((config, configIndex) => (
                        <div key={configIndex} className='x_card card text-white bg-light mb-3'>
                            <div className='x_card-header card-header'>{config.name}</div>
                            <div className='x_card-body card-body'>
                                <button disabled={readOnly} className={`x_clearAll btn btn-sm btn-danger ${readOnly ? 'x_isHidden' : ''}`} onClick={() => handleClearAll(configIndex)}>Clear All</button>
                                <ReactTags
                                    tags={config.headers.map((header, tagIndex) => ({ id: `${configIndex}-${tagIndex}`, text: header }))}
                                    delimiters={delimiters}
                                    handleDelete={i => handleDelete(configIndex, i)}
                                    handleAddition={tag => handleAddition(configIndex, tag)}
                                    handleDrag={(tag, currPos, newPos) => handleDrag(configIndex, tag, currPos, newPos)}
                                    inputFieldPosition='top'
                                    autocomplete
                                    classNames={{
                                        tags: 'x_tags',
                                        tag: 'x_tag',
                                        tagInput: 'x_tagInput',
                                        tagInputField: 'x_tagInputField',
                                        selected: 'x_selected',
                                        remove: 'x_remove',
                                        suggestions: 'x_suggestions',
                                        activeSuggestion: 'x_activeSuggestion',
                                        editTagInput: 'x_editTagInput',
                                        editTagInputField: 'x_editTagInputField',
                                        clearAll: 'x_clearAll'
                                    }}
                                    placeholder='Add Column Header to Include'
                                    editable={!readOnly}
                                    onTagUpdate={(i, newTag) => handleTagUpdate(configIndex, i, newTag)}
                                    readOnly={readOnly}
                                    autofocus={false}
                                />
                            </div>
                        </div>
                    ))}

                    {readOnly ? 
                        <div className='x_actionButtons'>
                            <button className='btn btn-info' onClick={handleConfigure}>
                                <i className='fa-solid fa-gears'/> Configure
                            </button>
                            <ActivityLogs logs={logs}/>
                        </div> :
                        <div className='x_actionButtons'>
                            <SaveChanges handleSaveChanges={handleSaveChanges}/>
                            <CancelChanges handleCancelChanges={handleCancelChanges}/>
                        </div>
                    }
                </React.Fragment>
            }
        </div>
    )
}

const KeyCodes = {
    comma: 188,
    enter: 13
}

const delimiters = [KeyCodes.comma, KeyCodes.enter]