import React, { useState } from 'react'
import Modal from 'react-modal'

import './Modals.css'

export default function CancelChanges({handleCancelChanges}) {
    const [modalIsOpen, setIsOpen] = useState(false)

    const openModal = () => {
        setIsOpen(true)
    }

    const closeModal = () => {
        setIsOpen(false)
    }

    const handleYes = () => {
        handleCancelChanges()
        closeModal()
    }

    return (
        <div>
            <button className='btn btn-danger' onClick={openModal}>Cancel Changes</button>
            <Modal
                className='x_saveModal'
                isOpen={modalIsOpen}
                onAfterOpen={()=>{}}
                onRequestClose={closeModal}
                shouldCloseOnEsc={false}
                shouldCloseOnOverlayClick={false}
                ariaHideApp={false}
            >
                <h4>Are you sure you want to cancel your changes?</h4>
                <div>All changes made will be reverted back and will not be saved</div>
                <div className='x_buttonsContainer'>
                    <button className='btn btn-success x_yesButton' onClick={handleYes}>Yes</button>
                    <button className='btn btn-danger x_cancelButton' onClick={closeModal}>Cancel</button>
                </div>
            </Modal>
        </div>
    )
}